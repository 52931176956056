'use strict';

(function (w, d) {
    var timer;
    var timeout = 300; // timeout to close flyouts, in ms.
    var current;
	var isOpenClass = 'is-open';
    var isActiveClass = 'is-active';
	var hasOverlayClass = 'has-menu-overlay';
    var isMobile = false;

    var HeaderNav = w.HeaderNav = {};
    var ToggleMenu = w.ToggleMenu = {};

    HeaderNav.init = function () {
        var nav = d.querySelector('[data-headernav]');

        if (nav) {
            // Find items containing submenus.
            var items = nav.querySelectorAll('.menu-item-has-children');

            [...items].forEach(function (item) {
                item.addEventListener('mouseenter', function(e) {
                    if (isMobile) return;
                    clearTimeout(timer);
                    if (current && current != this) {
                        clearCurrent();
                    }
					setCurrent(this);
                });
                item.addEventListener('mouseleave', function(e) {
                    if (isMobile) return;
                    timer = setTimeout(clearCurrent, timeout);
                });
                item.querySelectorAll('span a').forEach(function (link) {
                    link.addEventListener('click', function(e) {
                        if (isMobile) {
                            e.preventDefault();
                        }
                    }
                )});
                const button = item.querySelector('button');
                // button?.addEventListener('touchstart', setMobile, {passive: true});
                // button?.addEventListener('click', function(e) {
                //     const item = this.closest('li');
                //     console.log(item);
                //     if (current && current == item) {
                //         clearCurrent();
                //     } else {
                //         clearCurrent();
                //         setCurrent(item);
                //     }
                // });

                button.closest('li')?.addEventListener('touchstart', setMobile, {passive: true});
                button.closest('li')?.addEventListener('click', function(e) {
                    if (current && current == this) {
                        clearCurrent();
                    } else {
                        clearCurrent();
                        setCurrent(this);
                    }
                });
            });
        }
    };

    ToggleMenu.init = function() {
        var menu = document.querySelector('.main-nav');
        document
            .querySelector('.menu-toggle')?.addEventListener('click', function(e) {
                this.classList.toggle(isActiveClass);
                menu.classList.toggle(isOpenClass);
                d.body.classList.toggle(hasOverlayClass);
            });
    };

    function clearCurrent(keepOverlay) {
        current?.classList.remove(isOpenClass);
        current = null;
        if (!isMobile) d.body.classList.remove(hasOverlayClass);
    }

	function setCurrent(menuItem) {
		menuItem.classList.add(isOpenClass);
        current = menuItem;
		d.body.classList.add(hasOverlayClass);
	}

    function setMobile() {
        isMobile = true;
    }

    d.addEventListener('DOMContentLoaded', HeaderNav.init);
    d.addEventListener('DOMContentLoaded', ToggleMenu.init);


    var date = new Date();
    var hour = date.toLocaleString('en-GB', { hour: 'numeric', timeZone: 'Europe/London' });
    var weekday = date.toLocaleString('en-GB', { weekday: 'long', timeZone: 'Europe/London' });
    const elements = document.querySelectorAll('.site-header__contact-us');
    if ( (hour > 9 || hour < 17) && ('Saturday' != weekday && 'Sunday' != weekday) ) {
        elements.forEach((element) => {
            element.classList.add('is-open');
        });
    } else {
        elements.forEach((element) => {
            element.classList.remove('is-open');
        });
    }

    /** Change the height site-header__nav when the first header container is sticky */
    let firstHeaderContainer = document.querySelector('.site-header__container');

    if (firstHeaderContainer) {
        let distanceFromTop = firstHeaderContainer.offsetTop;

        let headerNav = document.querySelector('.site-header__nav')
        headerNav.style.top = (distanceFromTop + 80) + 'px';
    }

})(window, document);


